<template>
    <div class="pb-5">
        <div class="row">
            <div class="col-8">
                <mercur-card>
                    <h3>Log</h3>
                    <log-list class="history" :keywords="keywords"></log-list>
                </mercur-card>
            </div>
            <div class="col-4">
                <mercur-card class="c-card">
                    <log-form></log-form>
                </mercur-card>
            </div>
        </div>
    </div>
</template>

<script>
import LogForm from '@/components/elements/order/LogForm'
import LogList from '@/components/elements/order/LogList'

export default {
    name: 'OrderLineHistory',
    components: { LogForm, LogList },
    data () {
        return {
            keywords: ['order line', 'artwork'],
        }
    },
}
</script>

<style lang="scss" scoped>
    .history {
        /deep/ .c-log-list {
            height: auto;
            max-height: 100vh;
            overflow-y: scroll;
        }
    }
</style>
